import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Drawer, Layout, Menu} from "antd";

import SidebarContent from "./SidebarContent";

import Helpers from "../../util/Helpers";
import {MAIN_MENU} from "../../constants/ServerUrl";
import CustomScrollbars from "../../util/CustomScrollbars";
const {Sider} = Layout;

const Sidebar = (props) => {

    const dispatch = useDispatch();


    const {themeType, navStyle} = useSelector(({settings}) => settings);



    useEffect(() => {
        window.addEventListener('resize', () => {

        })
    }, [dispatch]);


    return (
      

        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            width={"222"}
            
            className="h-screen bg-blue"

        >
            <div className="flex items-center justify-center py-3 bg-blue-50 bg-opacity-90 cursor-pointer"
                 onClick={() => {
                     window.location.href = MAIN_MENU;
                 }}>

                <img className="w-36" alt="Phillips Outsourcing Logo" src="/images/logo.png"/>
            </div>
            <SidebarContent/>


        </Sider>
       
        
    )
};
export default Sidebar;
